import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BodyText from './BodyText';
import {
	makeStyles,
	Container,
	Box,
	Grid,
	TextField as TextField2,
} from '@material-ui/core';
import '../../main.css';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import click from '../../assets/images/jupiter.png';
// import musafirbazarlogo from "../../assets/images/musafirbazarlogo.png";
// import musafirbazarlogo from '../../assets/images/click.PNG';
// import musafirbazarlogo from "../../assets/images/max.png";
// import musafirbazarlogo from "../../assets/images/jupiter.png";
// import musafirbazarlogo from '../../assets/images/suzu.png';
// import musafirbazarlogo from '../../assets/images/worldwide.png';
import LoadingButton from '@mui/lab/LoadingButton';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation } from 'react-router-dom';
import HeaderSection from './HeaderSection';
import { toggleEditTicketModal } from '../../reducers/UiReducer';
import { fetchlogo } from '../../reducers/HomeReducer';
import EditTicketPriceModal from './EditTicketPriceModal';
import { FlightMailTicketDispatch } from '../../reducers/HomeReducer';
const useStyles = makeStyles((theme) => ({
	table: {
		background: '#fff',
		margin: '0px auto',
		width: '850px !important',
		padding: '30px 20px',
		webkitPrintColorAdjust: 'exact',
	},
	table2: {
		boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
		background: '#fff',
		borderSpacing: 0,
		padding: 0,
		margin: 0,
	},
	table3: {
		width: '100%',
		padding: 0,
		margin: 0,
	},
	row: {
		width: '60%',
		padding: '15px 40px',
	},
	row2: {
		width: '50%',
		border: '1px solid #333',
		borderLeft: 0,
		padding: '8px',
	},
	row3: {
		width: '50%',
		borderTop: '1px solid #333',
		borderBottom: '1px solid #333',
		padding: '5px',
	},
}));
const HotelTicket = () => {
	const { ticket } = useSelector((state) => state.home);
	const { editprice } = useSelector((state) => state.ui);
	const { bookingDetail } = useSelector((state) => state.tripjack);
	const { etrav } = useSelector((state) => state);
	const { agentlogin, agentlogoget } = useSelector((state) => state.user);
	const { logo } = useSelector((state) => state.home);
	console.log('ticket', ticket);
	// useEffect(() => {
	//     dispatch(FlightTicketDispatch());
	// },[])
	const location = useLocation();
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	console.log('Location', location);
	useEffect(() => {
		let form = {};

		dispatch(fetchlogo(form));
	}, []);
	const handleClick = () => {
		console.log('Hello');
		// setLoading(true);
		let formMail = {
			orderId: location.state.orderID,
			userEmail: location.state.formData.email,
		};
		dispatch(FlightMailTicketDispatch(formMail));
		window.alert('Ticket Set Successfully');
		// setLoading(false);
	};
	const pnr = bookingDetail
		? Object.entries(
				bookingDetail.result.itemInfos.AIR.travellerInfos[0].pnrDetails
		  ).forEach(([key, value]) => {
				return `${key},${value}`;
		  })
		: '';
	return (
		<>
			<HeaderSection id={1} />
			<Container style={{ marginTop: 100 }}>
				<LoadingButton
					id='back'
					onClick={() => history.push('/')}
					color='primary'
					loadingPosition='start'
					startIcon={<ArrowBackIosNewIcon />}
					variant='contained'>
					Back
				</LoadingButton>
			</Container>

			<table className={classes.table}>
				<tbody>
					<tr>
						<td>
							<table className={classes.table2}>
								<tbody>
									<tr>
										<td>
											<table className={classes.table3}>
												<tbody>
													<tr>
														<td className={classes.row}>
															{/* <img
                                src={
                                  "https://www.marinersforex.com/img/LOGO-MarinersForex.png"
                                }
                                style={{
                                  maxHeight: 50,
                                  maxWidth: 150,
                                }}
                              ></img> */}
														</td>
														<td style={{ width: '40%' }}>
															<BodyText
																textStyle={{
																	fontWeight: 600,
																	marginTop: 5,
																}}>
																MarinersForex
															</BodyText>
															<p
																style={{
																	fontWeight: 400,
																	fontSize: 14,
																	color: '#777',
																}}>
																Email: info@marinersforex.com
															</p>
															<p
																style={{
																	fontWeight: 400,
																	fontSize: 14,
																	color: '#777',
																}}>
																{/* Phone: +91 94704 42127 */}
															</p>
															{/* <p
																style={{
																	fontWeight: 400,
																	fontSize: 14,
																	color: '#777',
																}}>
																Address: Block - B 31, First Floor, Chandigarh Citi Center, VIP Rd, Zirakpur, Punjab 140603 
															</p> */}
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td style={{ padding: 10, background: 'darkblue' }}>
															<span
																style={{
																	color: 'white',
																	fontWeight: 'bold',
																	fontSize: 20,
																}}>
																Hotel Voucher{' '}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																// paddingLeft: 7,
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Reference No
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Confirmation No
															</span>
														</td>
													</tr>
													{location?.state?.bookingDetail?.result &&
													location?.state?.bookingDetail?.result.inventoryItems
														.length > 0 ? (
														location?.state?.bookingDetail?.result.inventoryItems.map(
															(item, index) => {
																return (
																	<tr>
																		<td
																			style={{
																				padding: 8,
																				border: '1px solid lightblue',
																			}}>
																			<span>ARCHANA</span>
																		</td>
																		<td
																			style={{
																				padding: 8,
																				border: '1px solid lightblue',
																			}}>
																			<span>
																				{
																					location?.state?.bookingDetail?.result
																						.pnr
																				}
																			</span>
																		</td>
																		<td
																			style={{
																				padding: 8,
																				border: '1px solid lightblue',
																			}}>
																			<span>{item.seatName}</span>
																		</td>
																	</tr>
																);
															}
														)
													) : (
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span>ARCHANA</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span>
																	{
																		location.state.bookingdetailhotel
																			.ConfirmationNo
																	}
																</span>
															</td>
															{/* <td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span>
																	{
																		location?.state?.bookingDetail?.result
																			.inventoryItems.seatName
																	}
																</span>
															</td> */}
														</tr>
													)}
												</tbody>
											</table>

											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Hotel Address Details:
															</span>
															<span style={{ marginLeft: 5 }}>
																{location.state.bookingdetailhotel.AddressLine1}
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Agency Address Details:
															</span>
															<span style={{ marginLeft: 5 }}>
																E Web Bazar Pvt. Ltd. H­14, Moti Nagar, Near JK
																Tower, Karmik Nagar, Dhanbad Ranchi 826004
																8877773388 Email: sales@musafirbazar.com
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Lead Passenger Name:
															</span>
															<span style={{ marginLeft: 5 }}>
																Nitish Kumar
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																No of Nights:
															</span>
															<span style={{ marginLeft: 5 }}>5</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Check In Date:
															</span>
															<span style={{ marginLeft: 5 }}>
																{' '}
																{
																	location.state.bookingdetailhotel.InitialCheckInDate.split(
																		'T'
																	)[0]
																}
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Check Out Date:
															</span>
															<span style={{ marginLeft: 5 }}>
																{' '}
																{
																	location.state.bookingdetailhotel.InitialCheckOutDate.split(
																		'T'
																	)[0]
																}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											{/* <table>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: 8,
                                border: "1px solid lightblue",
                              }}
                            >
                              <span
                                style={{
                                  color: "darkblue",
                                  fontWeight: "bold",
                                }}
                              >
                                Journey Date:
                              </span>
                              <span style={{ marginLeft: 5 }}>
                                {
                                  location?.state?.bookingDetail?.result.doj.split(
                                    "T"
                                  )[0]
                                }
                              </span>
                            </td>
                            <td
                              style={{
                                padding: 8,
                                border: "1px solid lightblue",
                              }}
                            >
                              <span
                                style={{
                                  color: "darkblue",
                                  fontWeight: "bold",
                                }}
                              >
                                Dep Time:
                              </span>
                              <span style={{ marginLeft: 5 }}>
                                {
                                  location?.state?.bookingDetail?.result
                                    .pickupTime
                                }
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table> */}
											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Inclusions:
															</span>
															<br />
															<span>
																{location.state.bookingdetailhotel.HotelName}
															</span>
															<br />
															<span>
																{
																	location.state.bookingdetailhotel
																		.HotelRoomsDetails[0].RoomTypeName
																}
															</span>
															<br />
															<span>Dates : 20th – 23rd August 2022</span>
															<br />
															<span>
																Room 01 : 74722, 74727 ­ Mr Shubham Ashish &
																Mrs. Akanksha Tiwari
															</span>
															<br />
															<span>
																Room 02 : 74724, 74729 ­ Mr Amar Kumar & Mrs.
																Kumari Ankita
															</span>
															<br />
															<span>Meal plan : FB plan</span>
															<br />
															<span>
																Return shared transfers by Seaplane + boat
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Remarks:
															</span>
															<br />
															<span>
																Please note that while your booking had been
																confirmed and is guaranteed, the rooming list
																with your name may not be adjusted in the
																hotel's reservation system until closer to
																arrival.
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Booking Terms & Conditions:
															</span>
															<br />
															<ol>
																<li>
																	1. You must present a photo ID at the time of
																	check in. Hotel may ask for credit card or
																	cash deposit for the extra services at the
																	time of check in
																</li>
																<li>
																	2. You must present a photo ID at the time of
																	check in. Hotel may ask for credit card or
																	cash deposit for the extra services at the
																	time of check in
																</li>
																<li>
																	3. We don't accept any responsibility for
																	additional expenses due to the changes or
																	delays in air, road, rail, sea or indeed of
																	any other causes, all such expenses will have
																	to be borne by passengers
																</li>
																<li>
																	4. In case of wrong residency & nationality
																	selected by user at the time of booking; the
																	supplement charges may be applicable and need
																	to be paid to the hotel by guest on check in/
																	check out.
																</li>
																<li>
																	5. Any special request for bed type, early
																	check in, late check out, smoking rooms, etc
																	are not guaranteed as subject to availability
																	at the time of check in.
																</li>
															</ol>
														</td>
													</tr>
												</tbody>
											</table>

											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Contact Details:
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Phone:
															</span>
															<span style={{ marginLeft: 5 }}>
																+91 8877773388
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Email:
															</span>
															<span style={{ marginLeft: 5 }}>
																sales@musafirbazar.com
															</span>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<table
				className={classes.table}
				style={{ marginTop: 20, marginBottom: 20 }}>
				<tbody>
					<tr>
						<td>
							<LoadingButton
								id='myPrntbtn'
								onClick={() => window.print()}
								loadingPosition='start'
								startIcon={<PrintIcon />}
								variant='contained'>
								Print
							</LoadingButton>
						</td>
						{/* <td>
							<LoadingButton
								id='download'
								loadingPosition='start'
								startIcon={<DownloadForOfflineIcon />}
								variant='contained'>
								Download
							</LoadingButton>
						</td> */}
						{/* <td>
							<LoadingButton
								id='downloadwprice'
								loadingPosition='start'
								startIcon={<DownloadForOfflineIcon />}
								variant='contained'>
								Download without price
							</LoadingButton>
						</td> */}
						<td>
							<LoadingButton
								id='mail'
								// color='secondary'
								onClick={handleClick}
								// loading={loading}
								loadingPosition='start'
								startIcon={<EmailIcon />}
								variant='contained'>
								Mail
							</LoadingButton>
						</td>
						{/* <td>
							<LoadingButton
								id='editprice'
								// color='secondary'
								onClick={() => dispatch(toggleEditTicketModal(true))}
								// loading={loading}
								loadingPosition='start'
								startIcon={<EditIcon />}
								variant='contained'>
								Edit Price
							</LoadingButton>
						</td> */}
					</tr>
				</tbody>
			</table>
			{/* <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}> <Box onClick={()=>history.push('/ticketroundtrip')} style={{textAlign: 'center',
    backgroundColor: '#1976d2',cursor:'pointer'}}>
        <h2 style={{color:'white'}}>Continue to Round Ticket</h2>
      </Box></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      */}
			<EditTicketPriceModal />
		</>
	);
};

export default HotelTicket;
