import axios from 'axios';
import { axiosInstance } from '../Axios';
import {
	loader,
	storeData,
	toggleErrorModal,
	setErrorMessage,
	setSuccessMessage,
	toggleBPNRModal,
	setBookingMessage,
	setSelectedOrigin,
	setSelectedDepart,
	toggleSuccessModal,
	toggleHolidayModal,
	togglePGModal,
	toggleSessionExpiredModal,
	toggleEasebuzzModal,
	toggleAPIFailedModal,
	loaderNext,
} from './UiReducer';
import {
	flightBalanceEtravDispatch,
	flightPaymentDebitEtravDispatch,
	flightTicketEtravDispatch,
} from './EtravReducer';
import { walletBalanceAgentDispatch } from './UserReducer';
import { HoldSearchDispatch, holdSuccess } from './FDReducer';
const { createSlice } = require('@reduxjs/toolkit');

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loading2: false,
		lint: false,
		ltbo: false,
		letrav: false,
		trp: false,
		city: null,
		onewayflightdata: null,
		onewayflightdataEtrav: null,
		etravInt: null,
		flightBook: null,
		flightBookEtrav: null,
		flightreturn: null,
		fareRule: null,
		fareRuleEtrav: null,
		fareQuote: null,
		fareQuoteEtrav: null,
		ticketGDS: null,
		releasePNR: null,
		flightLCCTicket: null,
		bookingDetail: null,
		flightCancel: null,
		cancelStatus: null,
		cancellationCharge: null,
		SSREtrav: null,
		SeatMEtrav: null,
		roundTripEtrav: null,
		orderID: null,
		addBooking: null,
		ticket: null,
		ticketReturn: null,
		tripjack: null,
		mail: null,
		bookingHistoryTicket: null,
		ssrTBO: null,
		holdflt: null,
		TJKRound: null,
		flightreturnInt: null,
		currency: null,
		cashback: sessionStorage.getItem('cashback')
			? JSON.parse(sessionStorage.getItem('cashback'))
			: null,
		footer_menu: sessionStorage.getItem('footer_menu')
			? JSON.parse(sessionStorage.getItem('footer_menu'))
			: null,
		footer_submenu: sessionStorage.getItem('footer_submenu')
			? JSON.parse(sessionStorage.getItem('footer_submenu'))
			: null,
		coupan: sessionStorage.getItem('coupan')
			? JSON.parse(sessionStorage.getItem('coupan'))
			: null,
		logo: sessionStorage.getItem('logo')
			? JSON.parse(sessionStorage.getItem('logo'))
			: null,
		fareQuoteReturn: null,
		origin: null,
		depart: null,
		hotel: sessionStorage.getItem('hotel')
			? JSON.parse(sessionStorage.getItem('hotel'))
			: null,
		debit: null,
		enquiry: null,
		travelBlog: sessionStorage.getItem('travelBlog')
			? JSON.parse(sessionStorage.getItem('travelBlog'))
			: null,
		popularDesc: sessionStorage.getItem('popularDesc')
			? JSON.parse(sessionStorage.getItem('popularDesc'))
			: null,
		ad: sessionStorage.getItem('ad')
			? JSON.parse(sessionStorage.getItem('ad'))
			: null,
		admin: sessionStorage.getItem('admin')
			? JSON.parse(sessionStorage.getItem('admin'))
			: null,
		social: sessionStorage.getItem('admin')
			? JSON.parse(sessionStorage.getItem('admin'))
			: null,
		cancelticket: null,
		markup: null,
	},
	reducers: {
		cityRequest(state, action) {
			state.loading = true;
		},

		citySuccess(state, action) {
			// state.loading = false;
			state.city = action.payload;
			state.error = null;
		},
		addSearchRequest(state, action) {
			state.loading2 = true;
		},

		addSearchFail(state, action) {
			// state.loading = false;
			state.error = action.payload;
		},
		cityFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		APIRequest(state, action) {
			state.loading2 = true;
		},

		APISuccess(state, action) {
			state.loading2 = false;
			state.api = action.payload;
			state.error = null;
		},
		APIFail(state, action) {
			state.loading2 = false;
			state.error = action.payload;
		},
		currencyRequest(state, action) {
			state.loading = true;
		},

		currencySuccess(state, action) {
			state.loading = false;
			state.currency = action.payload;
			state.error = null;
		},
		currencyFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		// addSearchRequest(state, action) {
		// 	state.loading = true;
		// },

		// addSearchFail(state, action) {
		// 	// state.loading = false;
		// 	state.error = action.payload;
		// },
		SSREtravRequest(state, action) {
			state.loading = true;
		},
		SSREtravSuccess(state, action) {
			state.loading = false;
			state.SSREtrav = action.payload;
			state.error = null;
		},
		SSrEtravFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fareRuleRequest(state, action) {
			state.loading = true;
		},
		fareRuleSuccess(state, action) {
			state.loading = false;
			state.fareRule = action.payload;
			state.error = null;
		},
		fareRuleFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightMailRequest(state, action) {
			state.loading = true;
		},
		flightMailSuccess(state, action) {
			state.loading = false;
			state.mail = action.payload;
			state.error = null;
		},
		flightMailFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fareRuleEtravRequest(state, action) {
			state.loading = true;
		},
		fareRuleEtravSuccess(state, action) {
			state.loading = false;
			state.fareRuleEtrav = action.payload;
			state.error = null;
		},
		fareRuleEtravFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		SeatMEtravRequest(state, action) {
			state.loading = true;
		},
		SeatMEtravSuccess(state, action) {
			state.loading = false;
			state.SeatMEtrav = action.payload;
			state.error = null;
		},
		SeatMEtravFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fareQuoteRequest(state, action) {
			state.loading = true;
		},
		fareQuoteSuccess(state, action) {
			state.loading = false;
			state.fareQuote = action.payload;
			state.error = null;
		},
		fareQuoteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fareQuoteReturnRequest(state, action) {
			state.loading = true;
		},
		fareQuoteReturnSuccess(state, action) {
			state.loading = false;
			state.fareQuoteReturn = action.payload;
			state.error = null;
		},
		fareQuoteReturnFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		ssrTBORequest(state, action) {
			state.loading = true;
		},
		ssrTBOSuccess(state, action) {
			state.loading = false;
			state.ssrTBO = action.payload;
			state.error = null;
		},
		ssrTBOFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		fareQuoteEtravRequest(state, action) {
			state.loading = true;
		},
		fareQuoteEtravSuccess(state, action) {
			state.loading = false;
			state.fareQuoteEtrav = action.payload;
			state.error = null;
		},
		fareQuoteEtravFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		ticketGDSRequest(state, action) {
			state.loading = true;
		},
		ticketGDSSuccess(state, action) {
			state.loading = false;
			state.ticket = action.payload;
			state.error = null;
		},
		ticketGDSFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookingDetailRequest(state, action) {
			state.loading = true;
		},
		bookingDetailSuccess(state, action) {
			state.loading = false;
			state.bookingDetail = action.payload;
			state.error = null;
		},
		bookingDetailFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		searchRequest(state, action) {
			state.ltbo = true;
		},
		searchTripRequest(state, action) {
			state.trp = true;
		},
		searchEtravRequest(state, action) {
			state.letrav = true;
		},
		searchSuccess(state, action) {
			state.ltbo = false;
			state.onewayflightdata = action.payload;
			state.error = null;
		},
		searchSuccessHold(state, action) {
			// if (state.onewayflightdataEtrav) {
			//   state.loading = false;
			// }

			state.holdflt = action.payload;
			// state.error = null;
		},
		searchSuccessEtrav(state, action) {
			// if (state.onewayflightdata&&state.tripjack) {
			// 	state.loading = false;
			// }
			state.letrav = false;
			state.onewayflightdataEtrav = action.payload;
			state.error = null;
		},
		searchSuccessTJK(state, action) {
			// if (state.onewayflightdata&&state.onewayflightdataEtrav) {
			// 	state.loading = false;
			// }
			state.trp = false;
			state.tripjack = action.payload;
			state.error = null;
		},
		orderIDGenerate(state, action) {
			// state.loading2 = false;
			state.orderID = action.payload;
			state.error = null;
		},
		searchSuccessRoundEtrav(state, action) {
			state.letrav = false;
			state.roundTripEtrav = action.payload;
			state.error = null;
		},
		searchFail(state, action) {
			state.ltbo = false;
			state.error = action.payload;
		},
		searchTripFail(state, action) {
			state.trp = false;
			state.error = action.payload;
		},
		searchEtravFail(state, action) {
			state.letrav = false;
			state.error = action.payload;
		},
		searchReturnRequest(state, action) {
			state.ltbo = true;
		},
		searchReturnIntRequest(state, action) {
			state.lint = true;
		},
		searchRoundTripTJKRequest(state, action) {
			state.loading = true;
		},

		searchReturnEtravRequest(state, action) {
			state.letrav = true;
		},
		searchReturnSuccess(state, action) {
			// if (state.roundTripEtrav&&state.TJKRound) {

			// }
			state.ltbo = false;
			state.loading2 = false;
			state.flightreturn = action.payload;
			state.error = null;
		},
		searchReturnIntSuccess(state, action) {
			state.lint = false;
			state.flightreturnInt = action.payload;
			state.error = null;
		},
		searchSuccessTJKRound(state, action) {
			// if (state.roundTripEtrav&&state.flightreturn) {

			// }
			state.loading = false;
			state.TJKRound = action.payload;
			state.error = null;
		},
		searchReturnFail(state, action) {
			state.ltbo = false;
			state.error = action.payload;
		},
		searchReturnIntFail(state, action) {
			state.lint = false;
			state.error = action.payload;
		},
		searchRoundTripTJKFail(state, action) {
			// state.loading = false;
			state.error = action.payload;
		},
		searchReturnEtravFail(state, action) {
			state.letrav = false;
			state.error = action.payload;
		},
		flightBookRequest(state, action) {
			state.loading = true;
		},
		flightBookSuccess(state, action) {
			state.loading = false;
			state.flightBook = action.payload;
			state.error = null;
		},
		flightBookFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightaddBookingRequest(state, action) {
			state.loading = true;
		},
		flightaddBookingSuccess(state, action) {
			state.loading = false;
			state.addBooking = action.payload;
			state.error = null;
		},
		flightaddBookingSuccessOffline(state, action) {
			state.loading = false;
			state.addBooking = action.payload;
			state.error = null;
		},
		flightaddBookingFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightBookEtravRequest(state, action) {
			state.loading = true;
		},
		flightBookEtravSuccess(state, action) {
			state.loading = false;
			state.flightBookEtrav = action.payload;
			state.error = null;
		},
		flightBookEtravFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightLCCRequest(state, action) {
			state.loading = true;
		},
		flightLCCSuccess(state, action) {
			state.loading = false;
			state.ticket = action.payload;
			state.error = null;
		},
		flightLCCFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightLCC1WRequest(state, action) {
			state.loading = true;
		},
		flightLCC1WSuccess(state, action) {
			state.loading = false;
			state.ticket = action.payload;
			state.error = null;
		},
		flightLCC1WFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightLCCRequestReturn(state, action) {
			state.loading = true;
		},
		flightLCCSuccessReturn(state, action) {
			state.loading = false;
			state.ticketReturn = action.payload;
			state.error = null;
		},
		flightLCCFailReturn(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		releasePNRRequest(state, action) {
			state.loading = true;
		},
		releasePNRSuccess(state, action) {
			state.loading = false;
			state.releasePNR = action.payload;
			state.error = null;
		},
		releasePNRFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightCancelRequest(state, action) {
			state.loading = true;
		},
		flightCancelSuccess(state, action) {
			state.loading = false;
			state.flightCancel = action.payload;
			state.error = null;
		},
		flightCancelFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightCancelStatusRequest(state, action) {
			state.loading = true;
		},
		flightCancelStatusSuccess(state, action) {
			state.loading = false;
			state.cancelStatus = action.payload;
			state.error = null;
		},
		flightCancelStatusFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		cancellationChargeRequest(state, action) {
			state.loading = true;
		},
		cancellationChargeSuccess(state, action) {
			state.loading = true;
			state.cancellationCharge = action.payload;
			state.error = null;
		},
		cancellationChargeFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookingHistoryRequest(state, action) {
			state.loading = true;
		},
		bookingHistorySuccess(state, action) {
			state.loading = false;
			state.bookingHistoryTicket = action.payload;
			state.error = null;
		},
		bookingHistoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightCashbackRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightCashbackSuccess(state, action) {
			state.loading = false;
			state.cashback = action.payload;
			storeData('cashback', action.payload);
			state.error = null;
		},
		flightCashbackFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightcoupanRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightcoupanSuccess(state, action) {
			state.loading = false;
			state.coupan = action.payload;
			storeData('coupan', action.payload);
			state.error = null;
		},
		flightcoupanFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightFooterMenuRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightFooterMenuSuccess(state, action) {
			state.loading = false;
			state.footer_menu = action.payload;
			state.error = null;
			storeData('footer_menu', action.payload);
		},
		flightFooterMenuFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightFooterSub_MenuRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightFooterSub_MenuSuccess(state, action) {
			state.loading = false;
			state.footer_submenu = action.payload;
			state.error = null;
			storeData('footer_submenu', action.payload);
		},
		flightFooterSub_MenuFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightlogoRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightlogoSuccess(state, action) {
			state.loading = false;
			state.logo = action.payload;
			state.error = null;
			storeData('logo', action.payload);
		},
		flightlogoFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightoriginRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightoriginSuccess(state, action) {
			state.loading = false;
			state.origin = action.payload;
			state.error = null;
		},
		flightoriginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightdepartRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		flightdepartSuccess(state, action) {
			state.loading = false;
			state.depart = action.payload;
			state.error = null;
		},
		flightdepartFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		hotelRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		hotelSuccess(state, action) {
			state.loading = false;
			state.hotel = action.payload;
			storeData('hotel', action.payload);
			state.error = null;
		},
		hotelFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		debitRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		debitSuccess(state, action) {
			// state.loading = false;
			state.debit = action.payload;
			state.error = null;
		},
		debitFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		enquiryRequest(state, action) {
			state.loading = true;
			state.error = action.payload;
		},
		enquirySuccess(state, action) {
			state.loading = false;
			state.enquiry = action.payload;
			state.error = null;
		},
		enquiryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		travelBlogRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		travelBlogSuccess(state, action) {
			state.loading = false;
			state.travelBlog = action.payload;
			storeData('travelBlog', action.payload);
			state.error = null;
		},
		travelBlogFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		popularDescRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		popularDescSuccess(state, action) {
			state.loading = false;
			state.popularDesc = action.payload;
			storeData('popularDesc', action.payload);
			state.error = null;
		},
		popularDescFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		dealRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		dealSuccess(state, action) {
			state.loading = false;
			state.deal = action.payload;
			storeData('deal', action.payload);
			state.error = null;
		},
		dealFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adSuccess(state, action) {
			state.loading = false;
			state.ad = action.payload;
			storeData('ad', action.payload);
			state.error = null;
		},
		adFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		searchIntEtravRequest(state, action) {
			state.loading = true;
		},
		searchIntSuccessEtrav(state, action) {
			state.loading = false;
			state.etravInt = action.payload;
			state.error = null;
		},
		searchIntEtravFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adminRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adminSuccess(state, action) {
			state.loading = false;
			state.admin = action.payload;
			storeData('admin', action.payload);
			state.error = null;
		},
		adminFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		socialRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		socialSuccess(state, action) {
			state.loading = false;
			state.social = action.payload;
			storeData('social', action.payload);
			state.error = null;
		},
		socialFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		cancelticketRequest(state, action) {
			state.loading = true;
			state.error = action.payload;
		},
		cancelticketSuccess(state, action) {
			state.loading = false;
			state.cancelticket = action.payload;
			state.error = null;
		},
		cancelticketFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		markupRequest(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		markupSuccess(state, action) {
			state.loading = false;
			state.markup = action.payload;
			state.error = null;
		},
		markupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': process.env.REACT_APP_APIKEY,
	},
};
export const {
	cityRequest,
	citySuccess,
	cityFail,
	APIRequest,
	APISuccess,
	APIFail,
	currencyRequest,
	currencySuccess,
	currencyFail,
	fareRuleRequest,
	fareRuleSuccess,
	fareRuleFail,
	fareRuleEtravRequest,
	fareRuleEtravSuccess,
	fareRuleEtravFail,
	fareQuoteRequest,
	fareQuoteSuccess,
	fareQuoteFail,
	fareQuoteReturnRequest,
	fareQuoteReturnSuccess,
	fareQuoteReturnFail,
	fareQuoteEtravRequest,
	fareQuoteEtravSuccess,
	fareQuoteEtravFail,
	searchRequest,
	searchSuccess,
	searchSuccessHold,
	searchSuccessEtrav,
	orderIDGenerate,
	searchSuccessRoundEtrav,
	searchFail,
	flightBookRequest,
	flightBookSuccess,
	flightBookFail,
	flightaddBookingRequest,
	flightaddBookingSuccess,
	flightaddBookingFail,
	flightBookEtravRequest,
	flightBookEtravSuccess,
	flightBookEtravFail,
	flightLCCRequest,
	flightLCCSuccess,
	flightLCCFail,
	flightLCC1WRequest,
	flightLCC1WSuccess,
	flightLCC1WFail,
	flightLCCRequestReturn,
	flightLCCSuccessReturn,
	flightLCCFailReturn,
	searchReturnRequest,
	searchReturnEtravRequest,
	searchReturnSuccess,
	searchReturnFail,
	searchReturnEtravFail,
	searchReturnIntRequest,
	searchReturnIntSuccess,
	searchReturnIntFail,
	ticketGDSRequest,
	ticketGDSSuccess,
	ticketGDSFail,
	bookingDetailRequest,
	bookingDetailSuccess,
	bookingDetailFail,
	releasePNRRequest,
	releasePNRSuccess,
	releasePNRFail,
	flightCancelRequest,
	flightCancelSuccess,
	flightCancelFail,
	flightCancelStatusRequest,
	flightCancelStatusSuccess,
	flightCancelStatusFail,
	cancellationChargeRequest,
	cancellationChargeSuccess,
	cancellationChargeFail,
	SSREtravRequest,
	SSREtravSuccess,
	SSREtravFail,
	SeatMEtravRequest,
	SeatMEtravSuccess,
	SeatMEtravFail,
	searchRoundTripTJKRequest,
	searchSuccessTJK,
	searchRoundTripTJKFail,
	searchEtravRequest,
	searchEtravFail,
	searchTripRequest,
	searchTripFail,
	addSearchRequest,
	addSearchFail,
	flightMailFail,
	flightMailRequest,
	flightMailSuccess,
	searchSuccessTJKRound,
	flightaddBookingSuccessOffline,
	bookingHistoryRequest,
	bookingHistorySuccess,
	bookingHistoryFail,
	ssrTBORequest,
	ssrTBOSuccess,
	ssrTBOFail,
	flightCashbackRequest,
	flightCashbackSuccess,
	flightCashbackFail,
	flightFooterMenuRequest,
	flightFooterMenuSuccess,
	flightFooterMenuFail,
	flightFooterSub_MenuRequest,
	flightFooterSub_MenuSuccess,
	flightFooterSub_MenuFail,
	flightcoupanRequest,
	flightcoupanSuccess,
	flightcoupanFail,
	flightlogoRequest,
	flightlogoSuccess,
	flightlogoFail,
	flightoriginRequest,
	flightoriginSuccess,
	flightoriginFail,
	flightdepartRequest,
	flightdepartSuccess,
	flightdepartFail,
	hotelRequest,
	hotelSuccess,
	hotelFail,
	debitRequest,
	debitSuccess,
	debitFail,
	enquiryRequest,
	enquirySuccess,
	enquiryFail,
	travelBlogRequest,
	travelBlogSuccess,
	travelBlogFail,
	popularDescRequest,
	popularDescSuccess,
	popularDescFail,
	dealRequest,
	dealSuccess,
	dealFail,
	adRequest,
	adSuccess,
	adFail,
	searchIntEtravRequest,
	searchIntSuccessEtrav,
	searchIntEtravFail,
	adminRequest,
	adminSuccess,
	adminFail,
	socialRequest,
	socialSuccess,
	socialFail,
	cancelticketRequest,
	cancelticketSuccess,
	cancelticketFail,
	markupRequest,
	markupSuccess,
	markupFail,
} = actions;

export const fetchCityDispatch = (bodyData) => async (dispatch) => {
	try {
		// dispatch(cityRequest());
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Client-Service': 'srk-client',
				'Auth-Key': 'srkapi',
			},
		};
		const { data } = await axiosInstance.post(
			'home/flightairport',
			bodyData,
			config
		);
		// console.log(data);
		// setResult(data);
		dispatch(citySuccess(data));
		if (bodyData.limits === 8) {
			dispatch(setSelectedOrigin(data?.result[1]));
			dispatch(setSelectedDepart(data?.result[7]));
		}
	} catch (error) {
		// dispatch(toggleErrorModal(true));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			cityFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const fetchCurrencyDispatch = (currency) => async (dispatch) => {
	try {
		const { data } = await axios.get(
			`https://v6.exchangerate-api.com/v6/d90d8837431cce7af536da47/latest/INR`
		);

		dispatch(currencySuccess(data));
		console.log('Currency', data);
		// console.log('first', Object.entries(data.conversion_rates).map((item)=>{
		// 	return `${item[0]} ${item[1]}`
		// }))
	} catch (error) {
		dispatch(
			currencyFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchAPIChangeDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(APIRequest());
		const { data } = await axios.get('home/initial', config);

		dispatch(APISuccess(data));
		if (data.status === 200) {
			if (bodyData.JourneyType === 2) {
				dispatch(SearchReturnFormIntDispatch(bodyData));
				// dispatch(SearchEtravIntDispatch(bodyData));
			} else {
				if (data.result.TBO_FLIGHT === 'true') {
					dispatch(SearchFormDispatch(bodyData));
				}
				if (data.result.ETR_FLIGHT === 'true') {
					dispatch(SearchEtravFormDispatch(bodyData));
				}
				if (data.result.TJK_FLIGHT === 'true') {
					dispatch(SearchTripjackFormDispatch(bodyData));
				}
				// dispatch(HoldSearchDispatch(bodyData));
				// dispatch(HoldSearchDispatch(bodyData));
			}
		}
		// console.log('api', data);
	} catch (error) {
		dispatch(
			APIFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchAPIChangeFDDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(APIRequest());
		const { data } = await axios.get('home/initial', config);

		dispatch(APISuccess(data));
		if (data.status === 200) {
			dispatch(SearchFormDispatch(bodyData));
			dispatch(SearchEtravFormDispatch(bodyData));
			dispatch(HoldSearchDispatch(bodyData));
		}
		// console.log('api', data);
	} catch (error) {
		dispatch(
			APIFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const SearchFormDispatch =
	(bodyData, history, OriginO, des, origin) => async (dispatch) => {
		try {
			dispatch(searchSuccess(null));
			dispatch(searchRequest());

			const { data } = await axiosInstance.post(
				'flytbo/flightsearch',
				bodyData,
				config
			);
			// const FlyHold = await axiosInstance.post(
			// 	'FlyHold/flightsearch',
			// 	bodyData,
			// 	config
			// );
			// dispatch(searchSuccessHold(FlyHold.data));
			// const ID = await axiosInstance.post(
			// 	'flight/addsearchflight',
			// 	bodyData,
			// 	config
			// );
			dispatch(loaderNext(false));
			dispatch(searchSuccess(data));

			// dispatch(orderIDGenerate(ID.data));
		} catch (error) {
			// dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				searchFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const AddSearchFlightDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(addSearchRequest());
			dispatch(searchSuccess(null));
			dispatch(searchSuccessEtrav(null));
			dispatch(holdSuccess(null));
			const { data } = await axiosInstance.post(
				'flight/addsearchflight',
				bodyData,
				config
			);
			dispatch(orderIDGenerate(data));
			if (data.status === 200) {
				dispatch(fetchAPIChangeDispatch(bodyData));
			} else {
				dispatch(toggleAPIFailedModal(true));
			}
		} catch (error) {
			dispatch(toggleAPIFailedModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				addSearchFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const AddSearchFlightFDDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(addSearchRequest());
			dispatch(searchSuccess(null));
			dispatch(searchSuccessEtrav(null));
			dispatch(holdSuccess(null));
			const { data } = await axiosInstance.post(
				'flight/addsearchflight',
				bodyData,
				config
			);
			dispatch(orderIDGenerate(data));
			if (data.status === 200) {
				dispatch(fetchAPIChangeFDDispatch(bodyData));
			} else {
				dispatch(toggleAPIFailedModal(true));
			}
		} catch (error) {
			dispatch(toggleAPIFailedModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				addSearchFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const AddSearchFlightRoundtripDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(addSearchRequest());

			const { data } = await axiosInstance.post(
				'flight/addsearchflight',
				bodyData,
				config
			);
			dispatch(orderIDGenerate(data));
			if (data.status === 200) {
				dispatch(SearchReturnFormDispatch(bodyData));
			}
		} catch (error) {
			// dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				addSearchFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const SearchEtravFormDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(searchSuccessEtrav(null));
		dispatch(searchEtravRequest());
		const etrav = await axiosInstance.post(
			'FlyEtrav/flightsearch',
			bodyData,
			config
		);

		dispatch(searchSuccessEtrav(etrav.data));
	} catch (error) {
		dispatch(
			searchEtravFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const SearchEtravIntDispatch =
	(bodyData, history, OriginO, des, origin) => async (dispatch) => {
		try {
			dispatch(searchIntSuccessEtrav(null));
			dispatch(searchIntEtravRequest());
			const etrav = await axiosInstance.post(
				'FlyEtrav/flightsearch',
				bodyData,
				config
			);

			dispatch(searchIntSuccessEtrav(etrav.data));
		} catch (error) {
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				searchIntEtravFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const SearchTripjackFormDispatch =
	(bodyData, history, OriginO, des, origin) => async (dispatch) => {
		try {
			dispatch(searchSuccessTJK(null));
			dispatch(searchTripRequest());

			// history.push({
			// 	pathname: '/oneway',
			// 	state: {
			// 		body: bodyData,
			// 		OriginO: OriginO,
			// 	},
			// });

			const tjk = await axiosInstance.post(
				'FlyTjk/flightsearch',
				bodyData,
				config
			);

			dispatch(searchSuccessTJK(tjk.data));
			console.log('TJK', tjk.data);
		} catch (error) {
			// dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				searchTripFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const SearchReturnFormDispatch =
	(bodyData, history, selectedOrigin, selectedDepart) => async (dispatch) => {
		try {
			dispatch(searchReturnSuccess(null));
			dispatch(searchReturnRequest());

			const { data } = await axiosInstance.post(
				'flytbo/flightsearch',
				bodyData,
				config
			);
			// const etrav = await axiosInstance.post(
			// 	'FlyEtrav/flightsearch',
			// 	bodyData,
			// 	config
			// );
			dispatch(searchReturnSuccess(data));
			// if(data.status===200){
			// 	dispatch(SearchReturnFormDispatch(bodyData))
			// }
			// dispatch(searchSuccessRoundEtrav(etrav.data));
			// history.push({
			// 	pathname: '/roundway',
			// 	state: { returnBody: bodyData },
			// });
			// storeData('return', etrav.data);
			dispatch(loader(false));
		} catch (error) {
			dispatch(
				searchReturnFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const SearchReturnFormIntDispatch =
	(bodyData, history, selectedOrigin, selectedDepart) => async (dispatch) => {
		try {
			dispatch(searchReturnIntSuccess(null));
			dispatch(searchReturnIntRequest());

			// dispatch(loader(true));

			const { data } = await axiosInstance.post(
				'flytbo/flightsearch',
				bodyData,
				config
			);
			// const etrav = await axiosInstance.post(
			// 	'FlyEtrav/flightsearch',
			// 	bodyData,
			// 	config
			// );
			dispatch(searchReturnIntSuccess(data));
			// dispatch(searchSuccessRoundEtrav(etrav.data));
			// history.push({
			// 	pathname: '/roundway',
			// 	state: { returnBody: bodyData },
			// });
			// storeData('return', etrav.data);
			// dispatch(loader(false));
		} catch (error) {
			// dispatch(loader(false));
			dispatch(
				searchReturnIntFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const SearchReturnEtravFormDispatch =
	(bodyData, history, selectedOrigin, selectedDepart) => async (dispatch) => {
		try {
			dispatch(searchSuccessRoundEtrav(null));
			dispatch(searchReturnEtravRequest());

			// history.push({
			//   pathname:
			//     selectedOrigin.country_code === "IN" &&
			//     selectedDepart.country_code === "IN"
			//       ? "/roundway"
			//       : "international",
			//   state: { returnBody: bodyData },
			// });
			// dispatch(loader(true));

			const etrav = await axiosInstance.post(
				'FlyEtrav/flightsearch',
				bodyData,
				config
			);
			// dispatch(searchReturnSuccess(data));
			dispatch(searchSuccessRoundEtrav(etrav.data));
			// history.push({
			// 	pathname: '/roundway',
			// 	state: { RData: etrav.data, returnBody: bodyData },
			// });
			// storeData('return', etrav.data);
			dispatch(loader(false));
		} catch (error) {
			dispatch(loader(false));
			dispatch(
				searchReturnEtravFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightFareRuleDispatch =
	(ResultIndex, TraceId) => async (dispatch) => {
		try {
			let bodyData = {
				TraceId: TraceId,
				ResultIndex: ResultIndex,
			};
			// dispatch(fareRuleRequest());

			const { data } = await axiosInstance.post(
				'FlyTbo/flightfarerule',
				bodyData,
				config
			);

			// console.log(data);
			dispatch(fareRuleSuccess(data));
		} catch (error) {
			dispatch(
				fareRuleFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const SearchTripjackRoundTripDispatch =
	(bodyData, history, OriginO, des, origin) => async (dispatch) => {
		try {
			dispatch(searchRoundTripTJKRequest());
			// history.push({
			// 	pathname: '/oneway',
			// 	state: {
			// 		body: bodyData,
			// 		OriginO: OriginO,
			// 	},
			// });
			dispatch(searchSuccessTJKRound(null));
			const tjk = await axiosInstance.post(
				'FlyTjk/flightsearch',
				bodyData,
				config
			);

			dispatch(searchSuccessTJKRound(tjk.data));
			console.log('TJK', tjk.data);
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				searchRoundTripTJKFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightFareRuleEtravDispatch =
	(Search_Key, Flight_Key, Fare_Id) => async (dispatch) => {
		try {
			let formData = {
				Search_Key: Search_Key,
				Flight_Key: Flight_Key,
				Fare_Id: Fare_Id,
			};
			// dispatch(fareRuleEtravRequest());

			const { data } = await axiosInstance.post(
				'FlyEtrav/flightfarerule',
				formData,
				config
			);

			// console.log(data);
			dispatch(fareRuleEtravSuccess(data));
			if (data.status === 202) {
				// dispatch(toggleErrorModal(true));
				// dispatch(setErrorMessage(data.message));
			}
		} catch (error) {
			// dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				fareRuleFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightFareQuoteDispatch =
	(bodyData, ssr, dispatch) => async (dispatch) => {
		try {
			dispatch(fareQuoteRequest());

			const { data } = await axiosInstance.post(
				'FlyTbo/flightfarequote',
				bodyData,
				config
			);
			dispatch(fareQuoteSuccess(data));
			if (data.status === 200) {
				dispatch(FlightSSRTBODispatch(ssr));
			} else {
				dispatch(toggleAPIFailedModal(true));
			}
		} catch (error) {
			dispatch(toggleAPIFailedModal(true));
			dispatch(
				fareQuoteFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightFareQuoteReturnDispatch =
	(bodyData, ssr, dispatch) => async (dispatch) => {
		try {
			dispatch(fareQuoteReturnRequest());

			const { data } = await axiosInstance.post(
				'FlyTbo/flightfarequote',
				bodyData,
				config
			);

			dispatch(fareQuoteReturnSuccess(data));
			if (data.status === 200) {
				dispatch(FlightSSRTBODispatch(ssr));
			} else {
				dispatch(toggleAPIFailedModal(true));
			}

			// dispatch(loader(false));
		} catch (error) {
			dispatch(toggleAPIFailedModal(true));
			dispatch(
				fareQuoteReturnFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightSSRTBODispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(ssrTBORequest());

		const { data } = await axiosInstance.post(
			'FlyTbo/specialservicerequest',
			bodyData,
			config
		);
		// const { data } = await axiosInstance.get(
		// 	`/app/api/flight-fare-quote?TraceId=${TraceId}&ResultIndex=${rI}`
		// );
		// console.log(data);
		dispatch(ssrTBOSuccess(data));

		// dispatch(loader(false));
	} catch (error) {
		// dispatch(toggleErrorModal(true));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			ssrTBOFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const FlightFareQuoteEtravDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(fareQuoteEtravRequest());

		const { data } = await axiosInstance.post(
			'FlyEtrav/flightreprice',
			bodyData,
			config
		);

		dispatch(fareQuoteEtravSuccess(data));

		if (data.status === 200) {
			dispatch(flightBalanceEtravDispatch());
		} else {
			dispatch(toggleAPIFailedModal(true));
		}
	} catch (error) {
		dispatch(toggleAPIFailedModal(true));
		dispatch(
			fareQuoteEtravFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const FlightBookDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(flightBookRequest());

		const { data } = await axiosInstance.post(
			'FlyTbo/flightbookgds',
			bodyData,
			config
		);

		dispatch(flightBookSuccess(data));

		if (data.result.Response.Response) {
			let formData = {
				TraceId: bodyData.TraceId,
				PNR: data.result.Response.Response.PNR,
				BookingId: data.result.Response.Response.BookingId,
				orderId: bodyData.orderId,
				paymentId: bodyData.paymentId,
			};
			dispatch(FlightTicketGDSDispatch(formData, history));
		} else {
			dispatch(toggleSessionExpiredModal(true));
		}
	} catch (error) {
		dispatch(toggleSessionExpiredModal(true));
		dispatch(
			flightBookFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const FlightAddBookingDispatch =
	(bodyData, payment, payableAmout, handlePayment, location) =>
	async (dispatch) => {
		try {
			dispatch(flightaddBookingRequest());

			const { data } = await axiosInstance.post(
				'flight/addbookingflight',
				bodyData,
				config
			);
			dispatch(flightaddBookingSuccess(data));
			if (data.status === 200) {
				if (payment === 'offline') {
					dispatch(togglePGModal(true));
				} else if (payment === 'payworldline') {
					dispatch(toggleEasebuzzModal(true));
				}
			} else {
				dispatch(toggleAPIFailedModal(true));
			}
		} catch (error) {
			dispatch(toggleAPIFailedModal(true));
			dispatch(
				flightaddBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightSSREtravDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(SSREtravRequest());

		const { data } = await axiosInstance.post(
			'FlyEtrav/specialservicerequest',
			bodyData,
			config
		);
		// console.log(data);
		dispatch(SSREtravSuccess(data));
	} catch (error) {
		dispatch(
			SSREtravFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const FlightSeatMapEtravDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(SeatMEtravRequest());

		const { data } = await axiosInstance.post(
			'FlyEtrav/flightseatmap',
			bodyData,
			config
		);
		// console.log(data);
		dispatch(SeatMEtravSuccess(data));
	} catch (error) {
		dispatch(
			SeatMEtravFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const FlightBookEtravDispatch =
	(bodyData, history, agentlogin) => async (dispatch) => {
		try {
			dispatch(flightBookEtravRequest());

			const { data } = await axiosInstance.post(
				'FlyEtrav/flightbooking',
				bodyData,
				config
			);

			dispatch(flightBookEtravSuccess(data));
			if (data.status === 200 && data.result.Booking_RefNo) {
				let payment = {
					ProductId: '1',
					RefNo: data.result.Booking_RefNo,
					TransactionType: '0',
				};
				dispatch(
					flightPaymentDebitEtravDispatch(
						payment,
						data.result.Booking_RefNo,
						history,
						bodyData,
						agentlogin
					)
				);
				dispatch(
					walletBalanceAgentDispatch(
						agentlogin && agentlogin.result.result.email,
						agentlogin && agentlogin.result.result.id
					)
				);
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				flightBookEtravFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightTicketLCCDispatch =
	(bodyData, history, mail, agentlogin) => async (dispatch) => {
		try {
			dispatch(flightLCCRequest());
			console.log('LocationLCC', mail);
			const { data } = await axiosInstance.post(
				'FlyTbo/flightbooklcc',
				bodyData,
				config
			);

			dispatch(flightLCCSuccess(data));
			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
			if (data.status === 200) {
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				history.push({
					pathname: '/ticket',
					state: {
						mailBody: mail,
					},
				});
				let formMail = {
					orderId: bodyData.orderId,
					email: bodyData.Passengers[0].Email,
					url: 'https://nitish.musafirbazar.com/static/logo/clicknbook.PNG',
					admin_email: 'care.rmoney@gmail.com',
					admin_phone: '94704 42127',
				};
				// dispatch(FlightMailTicketDispatch(formMail));
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			// dispatch(loader(false));
			dispatch(
				flightLCCFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightTicketLCC1WDispatch =
	(bodyData, history, agentlogin) => async (dispatch) => {
		try {
			dispatch(flightLCC1WRequest());
			const { data } = await axiosInstance.post(
				'FlyTbo/flightbooklcc',
				bodyData,
				config
			);

			dispatch(flightLCC1WSuccess(data));
			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
			// dispatch(loader(false));
			// console.log(data.data);
			if (data.status === 200) {
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				history.push({
					pathname: '/ticket',
					state: {
						mailBody: bodyData,
					},
				});
				console.log('OneflightData');

				let formMail = {
					orderId: bodyData.orderId,
					email: bodyData.Passengers[0].Email,
					url: 'https://nitish.musafirbazar.com/static/logo/clicknbook.PNG',
					admin_email: 'care.rmoney@gmail.com',
					admin_phone: '94704 42127',
				};
				// dispatch(FlightMailTicketDispatch(formMail));
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}

			// console.log('Ticket');
			// }
		} catch (error) {
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				flightLCC1WFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightTicketLCCDispatchReturn =
	(bodyData, history, mail, agentlogin) => async (dispatch) => {
		try {
			dispatch(flightLCCRequestReturn());

			const { data } = await axiosInstance.post(
				'FlyTbo/flightbooklcc',
				bodyData,
				config
			);

			dispatch(flightLCCSuccessReturn(data));
			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
			// dispatch(loader(false));
			// console.log(data.data);
			if (data.status === 200) {
				// history.push({
				// 	pathname: '/ticketroundtrip',
				// 	state: {
				// 		mailBody: mail,
				// 	},
				// });
				let formMail = {
					orderId: mail.orderID,
					userEmail: mail.formData.email,
				};
				// dispatch(FlightMailTicketDispatch(formMail));
			} else {
				// dispatch(toggleErrorModal(true));
				dispatch(toggleBPNRModal(true));
				dispatch(setBookingMessage(data.message));
				// dispatch(setErrorMessage(data.message));
			}

			// console.log('Ticket');
			// }
		} catch (error) {
			dispatch(loader(false));
			dispatch(
				flightLCCFailReturn(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightMailTicketDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightMailRequest());

		const { data } = await axios.post(
			'https://api.musafirbazar.com/api/user/mrn',
			bodyData,
			config
		);

		dispatch(flightMailSuccess(data));
		// dispatch(loader(false));
		// console.log(data.data);
		// if (data.status === 200) {
		// 	history.push('/ticket');
		// 	// alert('This is Lcc Ticket');
		// } else {
		// 	dispatch(toggleErrorModal(true));
		// 	dispatch(setErrorMessage(data.message));
		// }

		// console.log('Ticket');
		// }
	} catch (error) {
		dispatch(loader(false));
		dispatch(
			flightMailFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const FlightTicketGDSDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(ticketGDSRequest());

			const { data } = await axiosInstance.post(
				'FlyTbo/flightticketgds',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(ticketGDSSuccess(data));
			if (data.status === 200) {
				let markupData = {
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(flightMarupAmount(markupData));
				// if(location.selectedReturn[0].AirlineCode&&location.selectedonward[0].AirlineCode){
				// 	history.push('/ticketroundtrip');
				// }else{
				history.push('/ticket');
				// }
				// alert('This is Non-Lcc Ticket');
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				ticketGDSFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightBookingDetailDispatch =
	(PNR, bookingId, history) => async (dispatch) => {
		try {
			dispatch(loader(true));
			dispatch(bookingDetailRequest());
			let formData = {
				PNR: PNR,
				BookingId: bookingId,
			};

			const { data } = await axiosInstance.post(
				'FlyTbo/flightbookingdetails',
				formData,
				config
			);
			// console.log(data);
			dispatch(bookingDetailSuccess(data));
			if (data) {
				history.push('/ticketdisplay');
			}
			dispatch(loader(false));
		} catch (error) {
			dispatch(loader(false));
			dispatch(
				bookingDetailFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const FlightReleasePNRDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(releasePNRRequest());

			const { data } = await axiosInstance.post(
				'FlyTbo/flightreleasepnr',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(releasePNRSuccess(data));
			dispatch(loader(false));
		} catch (error) {
			dispatch(loader(false));
			dispatch(
				releasePNRFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const FlightCancelDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(flightCancelRequest());

		const { data } = await axiosInstance.post(
			'FlyTbo/flighcancel',
			bodyData,
			config
		);
		// console.log(data);
		dispatch(flightCancelSuccess(data));
	} catch (error) {
		dispatch(
			flightCancelFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const FlightCancelStatusDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(flightCancelStatusRequest());

			const { data } = await axiosInstance.post(
				'FlyTbo/flighcancelstatus',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(flightCancelStatusSuccess(data));
		} catch (error) {
			dispatch(
				flightCancelStatusFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const FlightCancellationChargeDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(cancellationChargeRequest());

			const { data } = await axiosInstance.post(
				'FlyTbo/flighcancellationcharge',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(cancellationChargeSuccess(data));
		} catch (error) {
			dispatch(
				cancellationChargeFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const FlightAgentBookingHistoryDispatch =
	(PNR, bookingId, supplier, history) => async (dispatch) => {
		try {
			dispatch(loader(true));
			dispatch(bookingHistoryRequest());
			let formData = {
				PNR: PNR,
				BookingId: bookingId,
			};
			let formDataETR = {
				Airline_PNR: PNR,
				Booking_RefNo: bookingId,
			};

			const { data } = await axiosInstance.post(
				supplier === 'ETR'
					? 'FlyEtrav/flightreprint'
					: 'FlyTbo/flightbookingdetails',
				supplier === 'ETR' ? formDataETR : formData,
				config
			);
			// console.log(data);
			dispatch(bookingHistorySuccess(data));
			if (data) {
				history.push('/ticketdisplay');
			}
			dispatch(loader(false));
		} catch (error) {
			dispatch(loader(false));
			dispatch(
				bookingHistoryFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const fetchCashback = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightCashbackRequest());

		const { data } = await axiosInstance.post(
			'home/cashback',
			bodyData,
			config
		);
		console.log(data);
		dispatch(flightCashbackSuccess(data));
	} catch (error) {
		dispatch(
			flightCashbackFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchFooterMenu = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightFooterMenuRequest());

		const { data } = await axiosInstance.post(
			'home/footer_menu',
			bodyData,
			config
		);
		console.log(data);
		dispatch(flightFooterMenuSuccess(data));
	} catch (error) {
		dispatch(
			flightFooterMenuFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchFooterSub_Menu = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightFooterSub_MenuRequest());

		const { data } = await axiosInstance.post(
			'home/footer_submenu',
			bodyData,
			config
		);
		console.log(data);
		dispatch(flightFooterSub_MenuSuccess(data));
	} catch (error) {
		dispatch(
			flightFooterSub_MenuFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchcoupan = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightcoupanRequest());

		const { data } = await axiosInstance.post('home/coupan', bodyData, config);
		console.log(data);
		dispatch(flightcoupanSuccess(data));
	} catch (error) {
		dispatch(
			flightcoupanFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchlogo = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightlogoRequest());

		const { data } = await axiosInstance.post('home/logo', bodyData, config);
		console.log(data);
		dispatch(flightlogoSuccess(data));
	} catch (error) {
		dispatch(
			flightlogoFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchOrigin = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightoriginRequest());

		const { data } = await axiosInstance.post(
			'home/popular_destination',
			bodyData,
			config
		);
		console.log(data);
		dispatch(flightoriginSuccess(data));
	} catch (error) {
		dispatch(
			flightoriginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchDepart = (bodyData) => async (dispatch) => {
	try {
		dispatch(flightdepartRequest());

		const { data } = await axiosInstance.post('home/deals', bodyData, config);
		console.log(data);
		dispatch(flightdepartSuccess(data));
	} catch (error) {
		dispatch(
			flightdepartFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchHotel = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelRequest());

		const { data } = await axiosInstance.post('home/hotel', bodyData, config);
		console.log(data);
		dispatch(hotelSuccess(data));
	} catch (error) {
		dispatch(
			hotelFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const flightDebitAmount = (bodyData) => async (dispatch) => {
	try {
		dispatch(debitRequest());

		const { data } = await axiosInstance.post(
			'flight/debitflightpayment',
			bodyData,
			config
		);
		console.log(data);
		dispatch(debitSuccess(data));
	} catch (error) {
		dispatch(
			debitFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const flightHolidayEnquiry = (bodyData) => async (dispatch) => {
	try {
		dispatch(enquiryRequest());

		const { data } = await axiosInstance.post(
			'agent/enquiry',
			bodyData,
			config
		);
		console.log(data);
		dispatch(enquirySuccess(data));
		if (data.status === 200) {
			dispatch(toggleSuccessModal(true));

			setTimeout(() => {
				dispatch(toggleSuccessModal(false));
				dispatch(toggleHolidayModal(false));
				dispatch(setSuccessMessage(''));
			}, 1200);
			dispatch(setSuccessMessage('Thank for Contacting'));
		} else {
			dispatch(toggleErrorModal(true));
			dispatch(setErrorMessage(data.result.message));
		}
	} catch (error) {
		dispatch(
			enquiryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchTravelBlog = (bodyData) => async (dispatch) => {
	try {
		dispatch(travelBlogRequest());

		const { data } = await axiosInstance.post(
			'home/travel_blog',
			bodyData,
			config
		);
		console.log(data);
		dispatch(travelBlogSuccess(data));
	} catch (error) {
		dispatch(
			travelBlogFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchPolularDestination = (bodyData) => async (dispatch) => {
	try {
		dispatch(popularDescRequest());

		const { data } = await axiosInstance.post(
			'home/popular_destination',
			bodyData,
			config
		);
		console.log(data);
		dispatch(popularDescSuccess(data));
	} catch (error) {
		dispatch(
			popularDescFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchDeal = (bodyData) => async (dispatch) => {
	try {
		dispatch(dealRequest());

		const { data } = await axiosInstance.post('home/deals', bodyData, config);
		console.log(data);
		dispatch(dealSuccess(data));
	} catch (error) {
		dispatch(
			dealFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchAd = (bodyData) => async (dispatch) => {
	try {
		dispatch(adRequest());

		const { data } = await axiosInstance.post(
			'home/advertisement',
			bodyData,
			config
		);
		console.log(data);
		dispatch(adSuccess(data));
	} catch (error) {
		dispatch(
			adFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchAdmin = (bodyData) => async (dispatch) => {
	try {
		dispatch(adminRequest());

		const { data } = await axiosInstance.post(
			'home/admindetail',
			bodyData,
			config
		);
		console.log(data);
		dispatch(adminSuccess(data));
	} catch (error) {
		dispatch(
			adminFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchsociallink = (bodyData) => async (dispatch) => {
	try {
		dispatch(socialRequest());

		const { data } = await axiosInstance.post(
			'home/sociallink',
			bodyData,
			config
		);
		console.log(data);
		dispatch(socialSuccess(data));
	} catch (error) {
		dispatch(
			socialFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchcancelticket = (bodyData) => async (dispatch) => {
	try {
		dispatch(cancelticketRequest());

		const { data } = await axiosInstance.post(
			'flight/cancelticket',
			bodyData,
			config
		);
		console.log(data);
		dispatch(cancelticketSuccess(data));
		if (data.status === 200) {
			window.alert('Your Request Submitted');
		}
	} catch (error) {
		dispatch(
			cancelticketFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const flightMarupAmount = (bodyData) => async (dispatch) => {
	try {
		dispatch(markupRequest());

		const { data } = await axiosInstance.post(
			'flight/markuptopartner',
			bodyData,
			config
		);
		console.log(data);
		dispatch(markupSuccess(data));
	} catch (error) {
		dispatch(
			markupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export default HomeReducer;
